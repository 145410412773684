<template>
  <div class="pageContol listWrap templateList templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()">直播课程</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">直播课节</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="直播课节名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem"
                >直播课节名称:</span
              >
              <el-input
                size="small"
                v-model="liveLessonName"
                type="text"
                placeholder="请输入直播课节名称"
                clearable
              />
            </div>
            <div title="直播状态" class="searchboxItem ci-full">
              <span class="itemLabel">直播状态:</span>
              <el-select
                v-model="liveState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in liveTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="createdPeriod('add', '')"
              >创建直播课节</el-button
            >
          </div>
        </div>
        <div>
          <span style="padding:8px 10px"
            >直播课程名称:<span style="color:red"> {{ liveCourseName }}</span>
          </span>
          <span style="padding:8px 10px"
            >关联班级:<span style="color:red">
              {{ liveProjectName }}</span
            ></span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100"
                fixed
              />
              <el-table-column
                label="直播课节名称"
                align="left"
                show-overflow-tooltip
                prop="liveLessonName"
                min-width="200"
                fixed
              />
              <el-table-column
                label="讲师"
                align="left"
                show-overflow-tooltip
                prop="teacherNames"
                min-width="150"
              />
              <el-table-column
                label="直播时间"
                align="left"
                show-overflow-tooltip
                min-width="220"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} ~
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="直播状态"
                align="left"
                show-overflow-tooltip
                prop="liveState"
                min-width="80"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("LIVE_STATE", scope.row.liveState || "--")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="实际开始时间"
                align="left"
                show-overflow-tooltip
                prop="startTimeActual"
                min-width="120"
              />
              <el-table-column
                label="操作"
                align="center"
                min-width="200"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    v-show="auditState == '30'"
                    @click="joinLive(scope.row.liveLessonId)"
                    >进入直播</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="createdPeriod('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="deleteliveLesson(scope.row.liveLessonId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="playTheTitle(scope.row.liveLessonId)"
                    >弹题设置</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="3%"
      :center="true"
      @close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="直播课节名称" prop="liveLessonName">
            <el-input
              v-model="Form.liveLessonName"
              type="text"
              size="small"
              maxlength="30"
              show-word-limit
              placeholder="请输入直播课节名称"
              :disabled="prohibit"
            />
          </el-form-item>
          <el-form-item label="直播时间" prop="liveDate">
            <el-date-picker
              v-model="Form.liveDate"
              type="datetimerange"
              align="right"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :disabled="prohibit"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="直播讲师" prop="teacherIds" class="zbjs" ref="zbjs">
            <el-select 
              v-model="Form.teacherIds" 
              clearable
              filterable
              multiple
              size="small"
              :disabled="prohibit"
              placeholder="请选择讲师">
            <el-option
              v-for="item in teachertree"
              :key="item.teacherQualificationId"
              :label="item.teacherQualificationName"
              :value="item.teacherQualificationId">
              <div style="display: flex;justify-content:space-between;align-items:center">
                <span>{{ item.teacherQualificationName }}</span>
                <el-popover
                  placement="right"
                  v-if="item.teacherQualificationId!= '-1000'"
                  trigger="hover">
                  <el-button type="text" slot="reference">证书</el-button>
                  <el-image
                    style="width: 500px; height: auto;"
                    fit="contain"
                    :src="item.qualificationImageUrl">
                  </el-image>
                </el-popover>
              </div>
            </el-option>
          </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center;"
      >
        <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')" :disabled="prohibit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="pztt"
      title="新增答题"
      :visible.sync="dialogVisible1"
      v-model="dynamicValidateForm"
      width="700px"
      top="3%"
      :center="true"
      ><el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic"
        ><template v-for="(qItem, qIndex) in dynamicValidateForm.data">
          <div class="shell" :key="qIndex">
            <el-form-item
              label="题目"
              :key="'ti' + qIndex"
              :prop="`data[${qIndex}].content`"
              :rules="{
                required: true,
                message: '题目不能为空',
                trigger: 'blur',
              }"
              ><el-input
                v-model="qItem.content"
                placeholder="请输入题目"
                size="small"
              ></el-input
              ><el-button @click.prevent="removeDomain(qItem)" size="small"
                >删除题目</el-button
              >
            </el-form-item>
            <el-form-item
              label="类型"
              :key="'lei' + qIndex"
              :prop="`data[${qIndex}].type`"
              :rules="{
                required: true,
                message: '类型不能为空',
                trigger: 'change',
              }"
            >
              <el-select
                v-model="qItem.type"
                placeholder="请选择类型"
                size="small"
                @change="dxdx(qIndex)"
              >
                <el-option
                  v-for="type in liveQuestionType"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                >
                </el-option>
              </el-select>
              <el-button @click.prevent="addOption(qIndex)" size="small"
                >新增选项</el-button
              >
            </el-form-item>
            <template v-for="(oItem, oIndex) in qItem.options">
              <el-form-item
                :label="'选项' + (oIndex + 1)"
                :key="'xx' + oIndex"
                :prop="`data[${qIndex}].options[${oIndex}].content`"
                :rules="{
                  required: true,
                  message: '选项不能为空',
                  trigger: 'change',
                }"
              >
                <el-checkbox v-model="oItem.correct" @change="dxyz(oIndex,qIndex)">正确答案</el-checkbox>
                <el-input
                  v-model="oItem.content"
                  placeholder="请输入选项内容"
                  size="small"
                  style="width: 60%;"
                ></el-input>
                <el-button @click.prevent="remOption(oItem, qIndex, oIndex)" size="small"
                  >删除选项</el-button
                >
              </el-form-item>
            </template>
            <span>第 {{ qIndex+1 }} 题 / 共 {{ dynamicValidateForm.data.length }} 题</span>
          </div>
        </template>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="addDomain">新增题目</el-button>
        <el-button class="bgc-bv" @click="submitForm('dynamicValidateForm')"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "liveClassFestival",
  components: {
    Empty,
    PageNum,
  },
  data() {
    return {
      auditState:"", // 审核状态
      liveLessonName: "", //直播课节名称
      liveState: "",
      liveTypeList: [], //直播状态
      dialogVisible: false, //期次弹窗
      dialogTitle: "添加直播课节",
      Form: {
        liveLessonName: "",
        liveDate: "",
        teacherIds: "",
      },
      rules: {
        liveLessonName: [
          { required: true, message: "请输入直播课节名称", trigger: "blur" },
        ],
        liveDate: [
          { required: true, message: "请选择直播时间", trigger: "blur" },
        ],
        teacherIds: [
          { required: true, message: "请选择直播讲师", trigger: "change" },
        ],
      },
      teachertree: [],
      // 当审核状态为：20: "待审核"/30: "已通过" 时不可编辑；否则可编辑:10: "未申请"/40: "已驳回"
      prohibit: false,
      dialogVisible1: false, // 弹题设置 -loading
      // 弹题的数据结构
      liveQuestionType: [
        {
          label: "单选",
          value: "10",
        },
        {
          label: "多选",
          value: "20",
        }
      ],
      dynamicValidateForm: {
        data: [
          {
            content: "",
            type: "",
            options: [
              {
                correct: false,
                content: "",
              },
            ],
          },
        ],
      },
      selectedLiveLessonId: ""
    };
  },
  mixins: [List],
  created() {
    this.getliveTypeList();
    this.auditState = this.$route.query.auditState;
  },
  mounted () {
  },
  updated() {
    this.fixCascader()
  },
  watch: {
    dialogVisible1(val) {
      if (!val) {
        this.dynamicValidateForm.data = [
          {
            content: "",
            type: "",
            options: [
              {
                correct: false,
                content: "",
              },
            ],
          },
        ];
      }
    }
  },
  methods: {
    fixCascader() {
      const cascader = document.querySelectorAll('.el-cascader .el-input__inner')     
      for (let index = 0; index < cascader.length; index++) {
        cascader[index].setAttribute('placeholder', '')
      }
    },
    getteachertree() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(res => {
        if (res.status == 0) {
         this.teachertree = [...res.data]
        }
      });
    },
    // 获取直播状态
    getliveTypeList() {
      const liveState = this.$setDictionary("LIVE_STATE", "list");
      const list = [];
      for (const key in liveState) {
        list.push({
          value: key,
          label: liveState[key],
        });
      }
      this.liveTypeList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveCourseId: this.$route.query.liveCourseId,
      };
      if (this.liveLessonName) {
        params.liveLessonName = this.liveLessonName;
      }
      if (this.liveState) {
        params.liveState = this.liveState;
      }
      this.doFetch(
        {
          url: "/biz/live/lesson/pageList",
          params,
          pageNum,
        },
        true,
        3
      );
      this.getliveName();
    },
    getliveName() {
      let parmar = {
        liveCourseId: this.$route.query.liveCourseId,
      };
      if (this.liveLessonName) {
        parmar.liveLessonName = this.liveLessonName;
      }
      if (this.liveState) {
        parmar.liveState = this.liveState;
      }
      this.$post(
        "/biz/live/lesson/queryCourseAndProjectName",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.liveCourseName = ret.data.liveCourseName;
            this.liveProjectName = ret.data.liveProjectName;
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 创建直播课节 */
    createdPeriod(stu,row) {     
      if (stu == "add") {
        if (this.auditState == "20" || this.auditState == "30") {
          this.$message({
            message: '审核已通过的课程不允许新增课节信息',
            type: 'warning'
          });
        } else {
          this.dialogTitle = "添加直播课节";
          this.dialogVisible = true;
          this.stu = stu;
          this.getteachertree();
        }       
      } else {
        if (this.auditState == "20" || this.auditState == "30") {
          this.dialogTitle = "查看直播课节";
          this.dialogVisible = true;
          this.liveLessonId = row.liveLessonId;
          this.getteachertree();
          this.getInfo(row.liveLessonId);
          this.prohibit = true;
        } else {
          this.dialogTitle = "修改直播课节";
          this.dialogVisible = true;
          this.liveLessonId = row.liveLessonId;
          this.stu = stu;
          this.getteachertree();
          this.getInfo(row.liveLessonId);
          this.prohibit = false;
        }      
      }
    },
    //编辑
    getInfo(liveLessonId) {
      this.$post("/biz/live/lesson/getInfo", { liveLessonId }, 3000, true, 3)
        .then((ret) => {
          this.Form = {
            ...ret.data,
            liveDate: [
              ret.data.startTime.replaceAll("/", "-"),
              ret.data.endTime.replaceAll("/", "-"),
            ],
          };
        })
        .catch((err) => {
          return;
        });
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      // this.Form = {
      //   liveLessonName: "",
      //   liveDate: "",
      //   teacherIds: "",
      // };
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        liveLessonName: this.Form.liveLessonName,
        startTime: this.Form.liveDate[0],
        endTime: this.Form.liveDate[1],
        teacherQualificationIds: this.Form.teacherIds,
        liveCourseId: this.$route.query.liveCourseId,
      };
      if (this.liveLessonId) {
        parmar.liveLessonId = this.liveLessonId;
      }
      this.$post(
        this.stu == "add"
          ? "/biz/live/lesson/insert"
          : "/biz/live/lesson/modify",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 删除 */
    deleteliveLesson(liveLessonId) {
      this.doDel(
        {
          url: "/biz/live/lesson/delete",
          msg: "你确定要删除该课节吗？",
          ps: {
            type: "post",
            data: { liveLessonId },
          },
        },
        true,
        3
      );
    },
    /* 进入直播 */
    joinLive(id) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveBroadcastStatus",
        query: {
          liveCourseId: this.$route.query.liveCourseId,
          liveLessonId: id,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= +35 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    goHerf() {
      this.$router.push({
        name: "liveCourse",
        params: {
          refresh: true,
          isAdd: false,
        },
      });
    },
    // 弹题设置
    playTheTitle(row) {
      this.selectedLiveLessonId = row;
      this.$post(
        "/biz/live/question/v1/query",
        {liveLessonId: this.selectedLiveLessonId},
        3000,
        true,
        3
      ).then(re => {
        if (re.data.length > 0) {
          this.dynamicValidateForm.data = re.data;
        }
        this.dialogVisible1 = true;
      })
    },
    // 弹题设置 - 新增题目
    addDomain() {
      this.dynamicValidateForm.data.push({
        content: "",
        type: "",
        options: [
          {
            content: "",
            correct: false,
          },
        ],
      });
    },
    // 弹题设置 - 删除题目
    removeDomain(item) {
      if(this.dynamicValidateForm.data.length<=1){
        this.$message({
          type: "warning",
          message: "最少增加一道题"
        });
      } else {
        var index = this.dynamicValidateForm.data.indexOf(item);
        if (index !== -1) {
          this.dynamicValidateForm.data.splice(index, 1);
        }
      }
      
    },
    // 弹题设置 - 新增选项
    addOption(index) {
      this.dynamicValidateForm.data[index].options.push({
        content: "",
        correct: false
      });
    },
    // 弹题设置 - (单选) - 清空已勾选的
    dxdx(qIndex){
      if(this.dynamicValidateForm.data[qIndex].type == '10'){
        for(let i=0;i<this.dynamicValidateForm.data[qIndex].options.length; i++){
          this.dynamicValidateForm.data[qIndex].options[i].correct = false
        }
      }
    },
    // 弹题设置 - (单选) - 勾选正确答案
    dxyz(oIndex,qIndex){
      if(this.dynamicValidateForm.data[qIndex].type == '10'){
        for(let i=0;i<this.dynamicValidateForm.data[qIndex].options.length; i++){
          this.dynamicValidateForm.data[qIndex].options[i].correct = false
        }
        this.dynamicValidateForm.data[qIndex].options[oIndex].correct = true
      }
    },
    // 弹题设置 - 删除选项
    remOption(item, qIndex, oIndex) {
      if(this.dynamicValidateForm.data[qIndex].options.length<=1){
        this.$message({
          type: "warning",
          message: "最少增加一个选项"
        });
      } else {
        this.dynamicValidateForm.data[qIndex].options.splice(oIndex, 1);
      }
      
    },
    // 弹题设置 - 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/live/question/v1/insert", { liveLessonId: this.selectedLiveLessonId, questions: this.dynamicValidateForm.data }, 3000, true, 3)
          .then((ret) => {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible1 = false;
          })
          .catch((err) => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.el-cascader__search-input {
  background: none !important;
}
.pztt {
  .el-form{
    .el-form-item {
      margin-bottom: 15px;
    }
    .shell {
      border-bottom: 1px solid #1b2e4b;
      margin-bottom: 15px;
    }
    .shell:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
    .el-checkbox {
      width: 20%;
      float: left;
    }
    .el-select,.el-input {
      width: 80%;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
}
</style>